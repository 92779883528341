// assets
import { ImportOutlined, CheckSquareOutlined, CloseSquareOutlined } from '@ant-design/icons';

// type
import { NavItemType } from 'types/menu';
import AllOrders from 'assets/images/side-menu/AllOrders';
import Invoices from 'assets/images/side-menu/invoices';

// icons
const icons = {
  ImportOutlined,
  CheckSquareOutlined,
  CloseSquareOutlined
};

// ==============================|| MENU ITEMS - FORMS & TABLES ||============================== //

const order: NavItemType = {
  id: 'order-managment',
  title: 'order-managment',
  type: 'group',
  permissionKey: [
    'VIEW_ACTIVE_ORDERS_LIST',
    'VIEW_COMPLETED_ORDERS_LIST',
    'VIEW_NEW_ORDERS_LIST',
    'VIEW_IN_PROGRESS_ORDERS_LIST',
    'VIEW_RETURN_ORDERS_LIST',
    'VIEW_CANCELED_ORDERS_LIST',
    'VIEW_SUCCESSFUL_ORDERS_LIST',
    'VIEW_ABORTED_ORDERS_LIST',
    'VIEW_INCOMPLETE_ORDERS_LIST',
    'VIEW_INVOICES_LIST'
  ],
  children: [
    {
      id: 'all',
      title: 'all',
      type: 'item',
      permissionKey: ['VIEW_ALL_ORDERS_LIST'],
      url: '/apps/orders/all',
      icon: (props) => {
        return <AllOrders fillColor={props?.style?.color === 'selected' ? '#222A95' : '#323232'} />;
      }
    },
    {
      id: 'active',
      title: 'active',
      type: 'collapse',
      url: '/apps/orders/active',
      collapsePermissionKeys: ['VIEW_ACTIVE_ORDERS_LIST'],
      permissionKey: [
        'VIEW_ACTIVE_ORDERS_LIST',
        'VIEW_NEW_ORDERS_LIST',
        'VIEW_IN_PROGRESS_ORDERS_LIST',
        'VIEW_RETURN_ORDERS_LIST',
        'VIEW_CANCELED_ORDERS_LIST'
      ],
      icon: icons.ImportOutlined,
      children: [
        {
          id: 'new',
          title: 'new',
          type: 'item',
          permissionKey: ['VIEW_NEW_ORDERS_LIST'],
          url: '/apps/orders/new'
        },
        {
          id: 'inprogress',
          title: 'inprogress',
          type: 'item',
          permissionKey: ['VIEW_IN_PROGRESS_ORDERS_LIST'],
          url: '/apps/orders/in-progress'
        },
        {
          id: 'return',
          title: 'return',
          type: 'item',
          permissionKey: ['VIEW_RETURN_ORDERS_LIST'],
          url: '/apps/orders/return'
        },
        {
          id: 'replace',
          title: 'replace',
          type: 'item',
          permissionKey: ['VIEW_REPLACE_ORDERS_LIST'],
          url: '/apps/orders/replace'
        },
        {
          id: 'cancelled',
          title: 'cancelled',
          type: 'item',
          permissionKey: ['VIEW_CANCELED_ORDERS_LIST'],
          url: '/apps/orders/cancelled'
        }
      ]
    },
    {
      id: 'completed',
      title: 'completed',
      type: 'collapse',
      url: '/apps/orders/completed',
      collapsePermissionKeys: ['VIEW_COMPLETED_ORDERS_LIST'],
      permissionKey: ['VIEW_COMPLETED_ORDERS_LIST', 'VIEW_SUCCESSFUL_ORDERS_LIST', 'VIEW_ABORTED_ORDERS_LIST'],
      icon: icons.CheckSquareOutlined,
      children: [
        {
          id: 'payment-transferred',
          title: 'payment-transferred',
          type: 'item',
          permissionKey: ['VIEW_SUCCESSFUL_ORDERS_LIST'],
          url: '/apps/orders/payment-transferred'
        },
        {
          id: 'order-aborted',
          title: 'order-aborted',
          type: 'item',
          permissionKey: ['VIEW_ABORTED_ORDERS_LIST'],
          url: '/apps/orders/aborted'
        }
      ]
    },
    {
      id: 'incomplete',
      title: 'incomplete',
      type: 'item',
      permissionKey: ['VIEW_INCOMPLETE_ORDERS_LIST'],
      url: '/apps/orders/incomplete',
      icon: icons.CloseSquareOutlined
    },
    {
      id: 'invoices',
      title: 'invoices',
      type: 'item',
      permissionKey: ['VIEW_INVOICES_LIST'],
      url: '/apps/orders/invoices/list',
      icon: (props) => {
        return <Invoices fillColor={props?.style?.color === 'selected' ? '#222A95' : '#323232'} />;
      }
    }
  ]
};

export default order;
